import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { IonicToastService } from 'src/app/services/ionic-toast.service';

@Component({
  selector: 'app-modalshippingtimes',
  templateUrl: './modalshippingtimes.page.html',
  styleUrls: ['./modalshippingtimes.page.scss'],
})
export class ModalshippingtimesPage implements OnInit {

  constructor(private modalCtr:ModalController,private fb:FormBuilder, private ionicToastService:IonicToastService) { }
  couponForm:FormGroup;
  deliveryDateSelected:any;
  ngOnInit() {
    this.formInit();
  }
  formInit(){
    this.couponForm = this.fb.group({
      deliveryDate:[null, [Validators.required]],
      deliveryTime:[null, [Validators.required]]
    });
  }
  showMyToast(message) {
    this.ionicToastService.showToast(message);
  }
  async close() {

    if (this.couponForm.getError) {
      if (this.couponForm.controls['deliveryDate'].hasError('required')){
        this.showMyToast("Invalid Date ");
        return false;
      }
      if (this.couponForm.controls['deliveryTime'].hasError('required')){
        this.showMyToast("Invalid  Time ");
        return false;
      }
    
    }
    const deliveryDate: Date = this.couponForm.get('deliveryDate').value;
    const deliveryTime: Date = this.couponForm.get('deliveryTime').value;
   // console.log('closeModalTip',deliveryDate,deliveryTime)
    
    await this.modalCtr.dismiss({deliveryDate,deliveryTime});
  }
  

getDate(event){
  //console.log("Event", event);
    this.deliveryDateSelected=event.target.value
    this.deliveryDateSelected=this.deliveryDateSelected.split('T')[0]
    //console.log( this.deliveryDateSelected);
}

}
