import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-modal-popover',
  templateUrl: './modal-popover.page.html',
  styleUrls: ['./modal-popover.page.scss'],
})
export class ModalPopoverPage implements OnInit {
  @Input() name: string;
  constructor(private modalCtr: ModalController, private fb: FormBuilder) { }
  couponForm: FormGroup;
  ngOnInit() {
    this.formInit();
  }
  formInit() {
    this.couponForm = this.fb.group({
      couponCode:['', Validators.required]
    });
  }
  async close() {
    const closeModal: string = this.couponForm.get('couponCode').value;
    await this.modalCtr.dismiss(closeModal);
  }

}
