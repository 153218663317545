import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,  Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { IonicToastService } from 'src/app/services/ionic-toast.service';

@Component({
  selector: 'app-modaltips',
  templateUrl: './modaltips.page.html',
  styleUrls: ['./modaltips.page.scss'],
})
export class ModaltipsPage implements OnInit {
  couponForm: FormGroup
  tipPerMode:boolean=true;
  tipText:string="Custom";
  tipMode:string="percent";
  constructor(private modalCtr:ModalController,private fb:FormBuilder, private ionicToastService:IonicToastService) { }

  ngOnInit() {
    this.formInit();
  }
  formInit() {
    this.couponForm = this.fb.group({
      amount:[null, [Validators.required,Validators.min(1),Validators.max(100)]]
    });
  }
  showMyToast(message) {
    this.ionicToastService.showToast(message);
  }
  async close() {

    if (this.couponForm.getError) {
      if (this.couponForm.controls['amount'].hasError('min')){
        this.showMyToast("Invalid tip ");
        return false;
      }
      if (this.couponForm.controls['amount'].hasError('max')){
        this.showMyToast("Invalid tip");
        return false;
      }

    }
    const closeModal: string = this.couponForm.get('amount').value;
    
    await this.modalCtr.dismiss({closeModal,tipMode:this.tipPerMode});
  }
  changeMode(){
    if(this.tipPerMode){
      this.tipPerMode=false;
      this.tipText="Default";
    }else{
      this.tipPerMode=true;
      this.tipText="Custom";
    }
    
  }

}
