import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeResourceUrl } from '../SafeResourceUrl.pipe';


@NgModule({
  declarations: [SafeResourceUrl],
  imports: [
    CommonModule
  ],
  exports: [
    SafeResourceUrl
  ]
})
export class SharedModule { }
