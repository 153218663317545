import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { IonicToastService } from '../services/ionic-toast.service';

@Component({
  selector: 'app-department-menu',
  templateUrl: './department-menu.page.html',
  styleUrls: ['./department-menu.page.scss'],
})
export class DepartmentMenuPage implements OnInit {
  departmentList:any=[] ;
  storeId =localStorage.getItem('selected_store');
  departmentId = this._activatedRoute.snapshot.params.departmentId; 
  
  constructor(private _activatedRoute: ActivatedRoute,
    private api: ApiService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private _router: Router,
    private ionicToastService: IonicToastService) { }

  ngOnInit() {
    
    if(this.departmentId==undefined){
      this.departmentId= localStorage.getItem('selected_department')
    }
    localStorage.setItem('selected_department',this.departmentId);
    console.log("departmentId===>",this.departmentId);
    this.getAllDepartments();    
  }
  getAllDepartments() {      
    this.api
      .getDepartments(this.storeId)
      .subscribe((rs) => {
        this.spinner.hide();
        if (rs.err) {
          this.ionicToastService.showToast(rs.error.message);
        }
        if (rs.message == 'Unauthenticated') {
          this.auth.logout();
        }        
          this.departmentList= rs.result;         
      });
  }

}
